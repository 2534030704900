<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Салаттар</div>
        <div class="foreign">Salads • Салаты</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/buzau.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Бұзау етімен балғын салат</div>
        <div class="textGrey">Mixed salat with veal</div>
        <div class="textGrey">Микс салат с телятиной</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/vkusnyi.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Салат Дәмді</div>
        <div class="textGrey">Delicious salad</div>
        <div class="textGrey">Салат вкусный</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/patsha.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Патша» салаты</div>
        <div class="textGrey">Royal salat</div>
        <div class="textGrey">Салат Королевский</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/baklazhan.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Қытырлақ баклажан</div>
        <div class="textGrey">Хрустящий Баклажан</div>
        <div class="textGrey">Crispy eggplant</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/saladparadise.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Ыстық салат «Paradise»</div>
        <div class="textGrey">Warm salat Paradise</div>
        <div class="textGrey">Теплый салат Paradise</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3090 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Балғын көкөніс»</div>
      <div class="textGrey">Greek salad</div>
      <div class="textGrey">Греческий</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Оливье» ет қосылған</div>
      <div class="textGrey">Olivier with meat</div>
      <div class="textGrey">Оливье с мясом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ачучук</div>
      <div class="textGrey">Achuchuk</div>
      <!-- <div class="textGrey">Crispy eggplant</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Цезарь» тауық етімен</div>
      <div class="textGrey">Цезарь с курицей</div>
      <div class="textGrey">Caeser with chicken</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Цезарь» теңіз ашшаянымен</div>
      <div class="textGrey">Цезарь с креветками</div>
      <div class="textGrey">Caeser salad with shrimps</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Райский</div>
      <div class="textGrey">Heavenly</div>
      <!-- <div class="textGrey">Caeser salad with shrimps</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Салат «Розбиф»</div>
      <div class="textGrey">Rosebeef salat</div>
      <div class="textGrey">Салат с Розбифом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Пісірілген асқабақ Күркетауық салаты</div>
      <div class="textGrey">Baked pumpkin and turkey salad</div>
      <div class="textGrey">Салат запеченный тыквой и индейкой</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Пісірілген қызылша» Сүзбе ірімшігімен</div>
      <div class="textGrey">Baked beet salad with cream cheese</div>
      <div class="textGrey">Салат запеченный свекла творожным сыром</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
