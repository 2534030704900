<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Тоқаш нан өнімдері</div>
        <div class="foreign">
          Bakery products <br />Изделия из булочного хлеба
        </div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/tabanan.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Нан астау»</div>
        <div class="textGrey">Nan astau</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        4490 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/balish.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Шағын бәліш жылқы етімен</div>
        <div class="textGrey">Mini pies with horse meat</div>
        <div class="textGrey">Мини пиражочки с кониной</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/mini.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Шағын чебурек</div>
        <div class="textGrey">Mini Chebureki</div>
        <div class="textGrey">Мини Чебурек</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1290 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/shygysnan.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Шығыс нан өнімдері»</div>
        <div class="textGrey">Assorted baked goods</div>
        <div class="textGrey">Ассорти из выпечки</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2490 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бауырсақ ірімшікпен</div>
      <div class="textGrey">Baursak with cheese</div>
      <div class="textGrey">Бауырсак с сыром</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Таба нан</div>
      <div class="textGrey">Bread in a frying pan</div>
      <div class="textGrey">Хлеб на сковороде</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бауырсақ балқаймақпен</div>
      <div class="textGrey">Bauyrsak with balkaimak</div>
      <!-- <div class="textGrey">Бауырсаки с джемам и с финиками</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Шағын Самса</div>
      <div class="textGrey">Mini Samsa</div>
      <div class="textGrey">Мини самса</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Нан астау»</div>
      <div class="textGrey">Nanastau</div>
      <!-- <div class="textGrey">Ассорти из выпечки</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      4490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
