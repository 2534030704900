<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Лимонад</div>
        <div class="foreign">Limonade</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Paradise</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Мохито</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ананас Манго</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Orange</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жемісті Лимонад</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қауын</div>
      <div class="textGrey">Арбуз</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Green</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Арбуз</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Хуба Буба</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Арбуз</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

