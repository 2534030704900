<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Ролл және Сет</div>
        <div class="foreign">Sets & Rolls • Роллы и сеты</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Филадельфия</div>
      <div class="textGrey">Philadelphia</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Канада</div>
      <div class="textGrey">Canada</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Калифорния</div>
      <div class="textGrey">California</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Гейша</div>
      <div class="textGrey">Geisha</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Поцелуй гейшы</div>
      <div class="textGrey">Geisha kiss</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Запеченная лава</div>
      <div class="textGrey">Stuffed lava</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Анти гейша</div>
      <div class="textGrey">Anti geisha</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Аригано темпура</div>
      <div class="textGrey">Arigano tempura </div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Мацури темпура</div>
      <div class="textGrey">Matsuri tempura</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Филадельфия темпура</div>
      <div class="textGrey">Philadelphia tempura</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Америка темпура</div>
      <div class="textGrey">America tempura</div>
      <!-- <div class="textGrey">Овощной букет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
