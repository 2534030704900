<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Тіскебасарлар</div>
        <div class="foreign">Cold snacks • Закуски</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/semga.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Семга етімен брускет</div>
        <div class="textGrey">Bruschette with salmon</div>
        <div class="textGrey">Брускет с семгой</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/beef.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Сыйыр етімен брускет</div>
        <div class="textGrey">Bruschette with beef</div>
        <div class="textGrey">Брускет с говядиной</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/chicken.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Тауық етімен брускет</div>
        <div class="textGrey">Chicken Bruschette</div>
        <div class="textGrey">Брускет куринный</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1190 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Көкөніс букеті</div>
      <div class="textGrey">Vegetable Bouquet</div>
      <div class="textGrey">Овощной букет</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Еттер жиынтығы</div>
      <div class="textGrey">Cold Cuts</div>
      <div class="textGrey">Мясной ассорти</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      5290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Балық етінің жиынтығы</div>
      <div class="textGrey">Assorted ripples</div>
      <div class="textGrey">Рыбное ассорти</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      6790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
