<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Сұйық тамақтар</div>
        <div class="foreign">Soups • Супы</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/borsch.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Борщ</div>
        <div class="textGrey">Borsch</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/siirsorpa.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Сорпа сиыр етімен</div>
        <div class="textGrey">Shorpa with beef</div>
        <div class="textGrey">Сорпа с говядиной</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Солянка етпен</div>
      <div class="textGrey">Solyanka with meat</div>
      <div class="textGrey">Солянка с мясом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тұшпара</div>
      <div class="textGrey">Dumplings</div>
      <div class="textGrey">Пельмени</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Том Ям» теңіз өнімдерімен</div>
      <div class="textGrey">Том-Ям С море продуктами</div>
      <div class="textGrey">Tom Yum with seafood</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тоқаш қосылған ірімшік сорпасы</div>
      <div class="textGrey">Cheese soup with buns</div>
      <div class="textGrey">Сырный суп с булочками</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кукси</div>
      <div class="textGrey">Kuksi</div>
      <!-- <div class="textGrey">Салат из свежих овощей</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Рамен» сиыр етімен</div>
      <div class="textGrey">Ramen with beef</div>
      <div class="textGrey">Рамен с говядиной</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Рамен» тауық етімен</div>
      <div class="textGrey">Ramen with chicken</div>
      <div class="textGrey">Рамен с курицей</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Рамен» ірімшікпен</div>
      <div class="textGrey">Ramen with Cheese</div>
      <div class="textGrey">Рамен с сыром</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кеспе көже ашыған құртпен</div>
      <div class="textGrey">Noodles soup with kurt</div>
      <div class="textGrey">Суп лапша с куртом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қазақша ет</div>
      <div class="textGrey">Meat in kazakh</div>
      <div class="textGrey">Мясо по казахский</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Мастава</div>
      <div class="textGrey">Mastova</div>
      <!-- <div class="textGrey">Мясо по казахский</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Чучвара</div>
      <div class="textGrey">Chuchvara</div>
      <!-- <div class="textGrey">Мясо по казахский</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Машқорда</div>
      <div class="textGrey">Mashqorda</div>
      <!-- <div class="textGrey">Мясо по казахский</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Сорпа қой етімен</div>
      <div class="textGrey">Lamb shorpa</div>
      <div class="textGrey">Сорпа из барана</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кеспе тауық етімен</div>
      <div class="textGrey">Homemade noodles</div>
      <div class="textGrey">Лапша по-домашнему</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Сиыр етімен фрикадельки</div>
      <div class="textGrey">Meatballs with beef</div>
      <div class="textGrey">Фрикадельки с говядиной</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Мампар</div>
      <div class="textGrey">Mampar</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Уха</div>
      <div class="textGrey">Ukha</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Чечевица</div>
      <div class="textGrey">Lentil Soup</div>
      <!-- <div class="textGrey">Фри с мясом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
