import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Categories from "../pages/categories.vue";
import About from "../pages/about.vue";
import Soups from "../pages/dishes/soups.vue";
import Pasta from "../pages/dishes/pasta.vue";
import Steak from "../pages/dishes/steak.vue";
import Banquet from "../pages/dishes/banquet.vue";
import Kebab from "../pages/dishes/kebab.vue";
import Pizza from "../pages/dishes/pizza.vue";
import Salad from "../pages/dishes/salad.vue";
import Snacks from "../pages/dishes/snacks.vue";
import RollsSets from "../pages/dishes/rollsets.vue";
import Sets from "../pages/dishes/sets.vue";
import Sous from "../pages/dishes/sous.vue";
import Garnish from "../pages/dishes/garnish.vue";
import National from "../pages/dishes/national.vue";
import Europe from "../pages/dishes/europe.vue";
import Oriental from "../pages/dishes/oriental.vue";
import Chinese from "../pages/dishes/chinese.vue";
import Lemonade from "../pages/dishes/lemonade.vue";
import Tea from "../pages/dishes/tea.vue";
import Milkshake from "../pages/dishes/milkshake.vue";
import Smoothie from "../pages/dishes/smoothie.vue";
import Coffee from "../pages/dishes/coffee.vue";
import Colddrinks from "../pages/dishes/colddrinks.vue";
import Kids from "../pages/dishes/kids.vue";
import Nan from "../pages/dishes/nan.vue";
import Deserts from "../pages/dishes/deserts.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/categories",
      component: Categories,
    },
    {
      path: "/about",
      name: "about",
      component: About,
    },
    {
      path: "/soups",
      component: Soups,
    },
    {
      path: "/pasta",
      component: Pasta,
    },
    {
      path: "/steak",
      component: Steak,
    },
    {
      path: "/banquet",
      component: Banquet,
    },
    {
      path: "/kebab",
      component: Kebab,
    },
    {
      path: "/pizza",
      component: Pizza,
    },
    {
      path: "/salad",
      component: Salad,
    },
    {
      path: "/snacks",
      component: Snacks,
    },
    {
      path: "/rollsets",
      component: RollsSets,
    },
    {
      path: "/sets",
      component: Sets,
    },
    {
      path: "/sous",
      component: Sous,
    },
    {
      path: "/garnish",
      component: Garnish,
    },
    {
      path: "/national",
      component: National,
    },
    {
      path: "/europe",
      component: Europe,
    },
    {
      path: "/oriental",
      component: Oriental,
    },
    {
      path: "/chinese",
      component: Chinese,
    },
    {
      path: "/lemonade",
      component: Lemonade,
    },
    {
      path: "/tea",
      component: Tea,
    },
    {
      path: "/milkshake",
      component: Milkshake,
    },
    {
      path: "/smoothie",
      component: Smoothie,
    },
    {
      path: "/coffee",
      component: Coffee,
    },
    {
      path: "/colddrinks",
      component: Colddrinks,
    },
    {
      path: "/kids",
      component: Kids,
    },
    {
      path: "/nan",
      component: Nan,
    },
    {
      path: "/deserts",
      component: Deserts,
    },
  ],
});

export default router;
