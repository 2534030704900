<template>
  <div class="main">
    <div class="main__logo2">
      <img src="@/assets/menu.svg" alt="" />
    </div>
  </div>

  <!-- КАТЕГОРИЯ НАЧАЛО -->
  <div class="category container">
    <div class="category__kaz">Ыстық тағамдар</div>
    <div class="category__foreign">Hot Dishes • Горячие блюда</div>
  </div>
  <!-- КАТЕГОРИЯ КОНЕЦ -->

  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/soups">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/soup.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Сұйық тамақтар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Soups • Супы</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->
  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/pasta">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/pasta.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Пасталар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Pasta • Паста</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->
  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/steak">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/steak.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Стейктер</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Steaks • Стейки</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->
  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/banquet">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/dishes.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">
          Ұжымдық мәзір <br />
          5-6 адамға
        </div>
        <div class="item__divider"></div>
        <div class="item__foreign item_small_font">
          Banquet dishes for 5-6 persons <br />
          Банкетные блюда на 5-6 персон
        </div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->
  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/kebab">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/kebab.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Кәуәптар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Kebabs • Шашлыки</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->
  <!-- СУБ КАТЕГОРИЯ НАЧАЛО -->
  <router-link to="/pizza">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/pizza.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Пицца</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Pizza • Пицца</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ КОНЕЦ -->

  <!-- КАТЕГОРИЯ -->
  <div class="category container">
    <div class="category__kaz">Суық тағамдар</div>
    <div class="category__foreign">Cold Dishes • Холодные блюда</div>
  </div>
  <!-- КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/salad">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/salad.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Салаттар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Salads • Салаты</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/snacks">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/snaks.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Тіскебасарлар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Cold snacks • Закуски</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/rollsets">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/roll.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Ролл және Сет</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Sets & Rolls<br />Роллы и сеты</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/sets">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/set.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Сет</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Sets • Сеты</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->

  <!-- КАТЕГОРИЯ -->
  <div class="category container">
    <div class="category__kaz">Қосымша</div>
    <div class="category__foreign">
      Additions to dishes • Дополнения к блюдам
    </div>
  </div>
  <!-- КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/sous">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/sauce.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Тұздықтар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Sauces • Соусы</div>
      </div>
    </div>
  </router-link>

  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/garnish">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/garnish.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Гарнир</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Garnish • Гарнир</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->

  <!-- КАТЕГОРИЯ -->
  <div class="category container">
    <div class="category__kaz">Ұлттық тағамдар</div>
    <div class="category__foreign">National Dishes • Национальные блюда</div>
  </div>
  <!-- КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/national">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/besh.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Ұлттық тағамдар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">A national dish<br />Национальные блюда</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/europe">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/europe.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Европа тағамдары</div>
        <div class="item__divider"></div>
        <div class="item__foreign">European dishes<br />Европейские блюда</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/oriental">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/oriental.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Шығыс тағамдары</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Oriental dishes<br />Восточные блюда</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/chinese">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/chinese.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Қытай тағамдары</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Chinese Cuisine<br />Китайская кухня</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->

  <!-- КАТЕГОРИЯ -->
  <div class="category container">
    <div class="category__kaz">Бар мәзірі</div>
    <div class="category__foreign">Bar Menu • Барное меню</div>
  </div>
  <!-- КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/lemonade">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/lemonade.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Лимонад</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Lemonade</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/tea">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/tea.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Шәйлар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Tea • Чай</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/deserts">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/desert.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Тәттілер</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Deserts • Десерты</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/milkshake">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/milkshake.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Сүт коктейльдер</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Milkshake<br />Молочные коктейли</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/smoothie">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/smoothie.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Смузи</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Smoothie</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/coffee">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/coffee.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Кофе</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Coffee</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/colddrinks">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/colddrinks.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Салқын сусындар</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Cold Drinks<br />Холодные напитки</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->

  <!-- КАТЕГОРИЯ -->
  <div class="category container">
    <div class="category__kaz">
      Балаларға арналған тағамдар мен тіскебасарлар
    </div>
    <div class="category__foreign">
      Children's meals and snacks <br />
      Детские блюда и закуски
    </div>
  </div>
  <!-- КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/nan">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/bread.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Тоқаш нан өнімдері</div>
        <div class="item__divider"></div>
        <div class="item__foreign item_small_font">
          Bakery products<br />Изделия из булочного хлеба
        </div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- СУБ КАТЕГОРИЯ -->
  <router-link to="/kids">
    <div class="item container">
      <div class="item__photo">
        <img src="@/assets/kids.png" alt="" />
      </div>
      <div class="item__name">
        <div class="item__kaz">Балалар мәзірі</div>
        <div class="item__divider"></div>
        <div class="item__foreign">Kids’ meal<br />Детское меню</div>
      </div>
    </div>
  </router-link>
  <!-- СУБ КАТЕГОРИЯ -->
  <!-- FOOTER -->
  <TheFooterCategories></TheFooterCategories>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_categories.scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
</style>

<script>
import TheFooterCategories from "@/components/TheFooterCategories.vue";
export default {
  components: {
    TheFooterCategories,
  },
};
</script>
