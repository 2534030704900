<template>
  <div class="footer_text">
    <div class="footer_kaz">Асыңыз дәмді болсын!</div>
    <div class="footer_foreign">Bon appetit!</div>
    <div class="footer_foreign">Приятного аппетита!</div>
  </div>
  <div class="footer__logo">
    <img src="@/assets/logotype.svg" alt="" />
  </div>
  <a href="https://www.codeshop.kz/" class="back">Сделано в Codeshop</a>
</template>

<style scoped>
.footer_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.6rem;
}

.footer_kaz {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  height: 5rem;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 300;
}

.footer_foreign {
  display: flex;
  align-items: center;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 1rem;
}

.footer__logo {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 3rem;
  justify-content: center;
}

.footer__logo img {
  width: 18rem;
}

.back {
  display: flex;
  justify-content: center;
  margin-bottom: 2.8rem;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  color: #613b1c;
}
</style>
