<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Смузи</div>
        <div class="foreign">Smoothie</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Құлпынай Банан</div>
      <div class="textGrey">330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Киви Алмамен</div>
      <div class="textGrey">330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тути Фрути</div>
      <div class="textGrey">330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жемісті смузи</div>
      <div class="textGrey">330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Шабдалымен смузи</div>
      <div class="textGrey">330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
