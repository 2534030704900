<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Балалар мәзірі</div>
        <div class="foreign">Kids’ meal • Детское меню</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/nuggets.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Нагетсы</div>
        <div class="textGrey">Nuggets</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1150 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ірімшік таяқшалары</div>
      <div class="textGrey">Cheese sticks</div>
      <div class="textGrey">Сырные палочки</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Клярдағы Қанатшалар</div>
      <div class="textGrey">Wings in batter</div>
      <div class="textGrey">Крылышки в кляре</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бургер</div>
      <div class="textGrey">Burger</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

