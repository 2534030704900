<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Европа тағамдары</div>
        <div class="foreign">European dishes • Европейские блюда</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/basaspaz.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Бас аспаз» тағамы</div>
        <div class="textGrey">Dishes from the Chef</div>
        <div class="textGrey">Блюда от шефа</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/tiriyaki.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Тауық еті терияки тұздығымен</div>
        <div class="textGrey">Chicken teriyaki</div>
        <div class="textGrey">Курица терияки</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2990 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->
  
  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/dorada.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Дорадо</div>
        <div class="textGrey">Sea Bream</div>
        <div class="textGrey">Судак с микс салатом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        5990 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->
  
  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/buktyrylgan.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Бұқтырылған Қабырға</div>
        <div class="textGrey">Braised rib</div>
        <div class="textGrey">Томленное ребро</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бифстрогонов</div>
      <!-- <div class="textGrey">Veal with potatoes Rashti</div> -->
      <div class="textGrey">Beef Strogonoff</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кордион blue</div>
      <div class="textGrey">Cordone blue</div>
      <!-- <div class="textGrey">Курица терияки</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Фрикасе</div>
      <div class="textGrey">Fricasse</div>
      <!-- <div class="textGrey">Курица терияки</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тауық Шницелі</div>
      <div class="textGrey">Chicken Scnitzel</div>
      <div class="textGrey">Куринный Шницель</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қуырылған картоп</div>
      <div class="textGrey">Homemede potatoes</div>
      <div class="textGrey">Картошка по домашнему</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тауық етінің котлеті</div>
      <div class="textGrey">Chicken cutlets</div>
      <div class="textGrey">Куринные котлеты</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қуырылған «Рамен»</div>
      <div class="textGrey">Fried Ramen</div>
      <div class="textGrey">Рамен жаренный</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Судак» микс салатпен</div>
      <div class="textGrey">Pike perch mix salad</div>
      <div class="textGrey">Судак с микс салатом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Миньон</div>
      <div class="textGrey">Minion</div>
      <!-- <div class="textGrey">Судак с микс салатом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Чопшиш</div>
      <div class="textGrey">Chop Shihs</div>
      <!-- <div class="textGrey">Судак с микс салатом</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Құс филесі</div>
      <div class="textGrey">Chicken fillet</div>
      <div class="textGrey">Филе птицы</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Француз еті</div>
      <div class="textGrey">French Meat</div>
      <div class="textGrey">Мясо по-французски</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>