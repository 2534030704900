<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Пасталар</div>
        <div class="foreign">Pasta • Паста</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/paulini.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Паулини</div>
        <div class="textGrey">Pauline</div>
        <!-- <div class="textGrey">Сольянка с мясом</div> -->
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2990 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->
  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/carbonara.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Карбонара</div>
        <div class="textGrey">Carbonara</div>
        <!-- <div class="textGrey">Сольянка с мясом</div> -->
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2590 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Альфредо тауық етімен</div>
      <div class="textGrey">Alfredo with Chicken</div>
      <div class="textGrey">Альфредо с курицей</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Фетучини семгамен</div>
      <div class="textGrey">Fetucini with Salmon</div>
      <div class="textGrey">Фетучини с семгой</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Болоньезе</div>
      <div class="textGrey">Bolognese</div>
      <!-- <div class="textGrey">Фетучини с семгой</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
