<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Стейктер</div>
        <div class="foreign">Steaks • Стейки</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>R-Beye</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      6390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Семга» стейк</div>
      <div class="textGrey">Salmon Steak</div>
      <div class="textGrey">Стейк из cемги</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      4790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бас аспаздан Стейк</div>
      <div class="textGrey">Shef`s Steak</div>
      <div class="textGrey">Cтейк от Шефа</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      6890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Медальон</div>
      <div class="textGrey">Medallions</div>
      <!-- <div class="textGrey">Фетучини с семгой</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      5290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>T-Bone</div>
      <!-- <div class="textGrey">Alfredo with Chicken</div> -->
      <!-- <div class="textGrey">Альфредо с курицей</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      6190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
