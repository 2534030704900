<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Пицца</div>
        <div class="foreign">Pizza • Пицца</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/margarita.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Маргарита</div>
        <!-- <div class="textGrey">Duck</div> -->
        <div class="textGrey">Margarita</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/pepperoni.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Пеперони</div>
        <!-- <div class="textGrey">Duck</div> -->
        <div class="textGrey">Pepperoni</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2290 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Пицца Болоньезе</div>
      <div class="textGrey">Pizza Bolognese</div>
      <!-- <div class="textGrey">Овощи на гриле</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Цезарь</div>
      <div class="textGrey">Caesar</div>
      <!-- <div class="textGrey">Овощи на гриле</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Мексикалық» пицца</div>
      <div class="textGrey">Mexican pizza</div>
      <!-- <div class="textGrey">Овощи на гриле</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Қазақ» пиццасы</div>
      <div class="textGrey">Kazakh pizza</div>
      <!-- <div class="textGrey">Овощи на гриле</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«4-Ірімшік»</div>
      <div class="textGrey">4-cheeses</div>
      <div class="textGrey">4-сыра</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Саңырау құлақ қосылған тауық еті</div>
      <div class="textGrey">Chicken with mushrooms</div>
      <div class="textGrey">Курица с грибами</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
