<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Қытай тағамдары</div>
        <div class="foreign">Chinese Cuisine • Китайская кухня</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/guiru.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Гуйру» лағман</div>
        <div class="textGrey">Guyru lagman</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2090 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/guirutsomyan.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Гуйру» Цомян</div>
        <div class="textGrey">Guiru</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        2090 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Суйру» лағман</div>
      <div class="textGrey">Suyru lagman</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Үйдің лағманы</div>
      <div class="textGrey">Home lagman</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Бас аспаз» лағманы</div>
      <div class="textGrey">lagman from the chef</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Суйру» Цомян</div>
      <div class="textGrey">Syuru Tsomyan</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Дин-дин» цомян</div>
      <div class="textGrey">Ding-ding Tsomyan</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қазан кебаб</div>
      <div class="textGrey">Kazan kebab</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қарын сай</div>
      <div class="textGrey">Karyn sai</div>
      <!-- <div class="textGrey">Вареники с сыром</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div> Картоп түйнектерімен бұзау еті</div>
      <div class="textGrey">Fries with meat</div>
      <div class="textGrey">Фри с мясом</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
