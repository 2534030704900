<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Тұздықтар</div>
        <div class="foreign">Sauces • Соусы</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Балқаймақ</div>
      <div class="textGrey">Balkaimak</div>
      <div class="textGrey">Балкаймак</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ketchinez</div>
      <div class="textGrey">Кетчинез</div>
      <!-- <div class="textGrey">Балкаймак</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Көкөніс тұздығы</div>
      <div class="textGrey">Vegetable souce</div>
      <div class="textGrey">Овощной соус</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Құрт тұздығы</div>
      <div class="textGrey">Kurt souce</div>
      <div class="textGrey">Соус курт</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ірімшік тұздығы</div>
      <div class="textGrey">Cheese sause</div>
      <div class="textGrey">Сырный соус</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кәуәп тұздығы</div>
      <div class="textGrey">Kebab Sauce</div>
      <div class="textGrey">Шашлычный соус</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
