<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Сүт коктейльдер</div>
        <div class="foreign">Milkshake • Молочные коктейли</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Классикалық 330ml</div>
      <div class="textGrey">Classic</div>
      <div class="textGrey">Классический</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Құлпынай 330ml</div>
      <div class="textGrey">Stawberry</div>
      <div class="textGrey">Клубничный</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Oreo 330ml</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Шоколадпен 330ml</div>
      <div class="textGrey">Chocolate</div>
      <div class="textGrey">Шоколадный</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бананмен 330ml</div>
      <div class="textGrey">Banana</div>
      <div class="textGrey">Банановый</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кокос Аспаны 330ml</div>
      <div class="textGrey">Сoconut heaven</div>
      <div class="textGrey">Кокосовый рай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

