<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Ұжымдық мәзір 5-6 адамға</div>
        <div class="foreign">
          Banquet dishes for 5-6 persons<br />Банкетные блюда на 5-6 персон
        </div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ас табақ</div>
      <div class="textGrey">Beshbarmak</div>
      <div class="textGrey">Бешбармак</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      18990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Шах» палау</div>
      <div class="textGrey">Shakh pilaf</div>
      <div class="textGrey">Шах плов</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      12990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Патша» Палауы</div>
      <div class="textGrey">Khan`s Pilaf</div>
      <div class="textGrey">Ханский плов</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      16990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Батыр» еті</div>
      <div class="textGrey">Heroic meat</div>
      <div class="textGrey">Богатырское мясо</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      16990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Кәуаптар жиынтығы</div>
      <div class="textGrey">Assorted shish kebab</div>
      <div class="textGrey">Ассорти шашлык</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      18990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Дапанжи</div>
      <div class="textGrey">Dapanzhi</div>
      <!-- <div class="textGrey">Ассорти шашлык</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      12990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Микс гриль» еттері</div>
      <div class="textGrey">Mix grilled meat</div>
      <div class="textGrey">Микс гриль мясо</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      27990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- DELETED ITEM -->
  <!-- <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Сыйыр қабырғасы» піскен көкөніспен</div>
      <div class="textGrey">Beef rib with vegetables</div>
      <div class="textGrey">Говяжие ребра с овощями</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      18990 Т
    </div>
  </div> -->
  <!-- DELETED ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бал қуырдақ</div>
      <div class="textGrey">Bal kuyrdak</div>
      <!-- <div class="textGrey">Говяжие ребра с овощями </div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      17990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Сірне</div>
      <div class="textGrey">Sirne</div>
      <!-- <div class="textGrey">Говяжие ребра с овощями </div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      17990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
