<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Шығыс тағамдары</div>
        <div class="foreign">Oriental dishes • Восточные блюда</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/manti.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Манты</div>
        <div class="textGrey">Manti</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қуырылған манты</div>
      <div class="textGrey">Fried manti</div>
      <div class="textGrey">Жаренные манты</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Палау</div>
      <div class="textGrey">Pilaf</div>
      <div class="textGrey">Плов</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қазан кебаб</div>
      <div class="textGrey">Kazan Kebab</div>
      <div class="textGrey">Казан кебаб</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Варкеники картоппен</div>
      <div class="textGrey">Potato dumplings</div>
      <div class="textGrey">Вареники с картошкой</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

