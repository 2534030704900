<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Кәуәптар</div>
        <div class="foreign">Kebabs • Шашлыки</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/bonelesschicken.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Тауықтың сүйексіз еті</div>
        <div class="textGrey">Boneless chicken</div>
        <div class="textGrey">Курица без костей</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1890 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Үйрек еті</div>
      <div class="textGrey">Duck</div>
      <div class="textGrey">Утка</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Сыйыр етімен люля</div>
      <div class="textGrey">Kebab wuth</div>
      <div class="textGrey">Люля говядина</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қанатшалар</div>
      <div class="textGrey">Wings</div>
      <div class="textGrey">Крылышки</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тауық етімен люля</div>
      <div class="textGrey">Chicken kebab</div>
      <div class="textGrey">Люля куринный</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қой қарбырғасы</div>
      <div class="textGrey">Lamb loin</div>
      <div class="textGrey">Корейка баранины</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қойдың сүйексіз еті</div>
      <div class="textGrey">Boneless lamb</div>
      <div class="textGrey">Баранина без костей</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Грильдегі саңырауқұлақтар</div>
      <div class="textGrey">Grilled champignons</div>
      <div class="textGrey">Шампинионы на гриле</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Грильдегі көкөністер</div>
      <div class="textGrey">Grilled vegetables</div>
      <div class="textGrey">Овощи на гриле</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

