<template>
  <div class="main">
    <div class="main__logo">
      <img src="@/assets/logotype.svg" alt="" />
    </div>
    <div class="main__menu">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div class="main__steppe container">
      <img src="@/assets/steppe.png" alt="" />
    </div>
    <router-link to="/about">
      <div class="infoButton">
        <img src="@/assets/info.svg" alt="" />
      </div>
    </router-link>
  </div>
  <div class="buttonContainer">
    <div class="main__button">
      <router-link to="/categories">
        <button class="buttonContent">
          <img src="@/assets/fork.svg" alt="" />
        </button>
      </router-link>
    </div>
  </div>
  <a href="https://www.instagram.com/paradiseyard_kulsary/" class="socialMedia"
    >Instagram</a
  >
</template>

<style lang="scss" src="@/components/style.scss"></style>
<!-- <style lang="scss"></style> -->

<script></script>
