<template>
  <div class="main">
    <!-- ЛОГОТИП -->
    <div class="main__logo">
      <img src="@/assets/logotype.svg" alt="" />
    </div>
    <!-- ЛОГОТИП -->
    <div class="infoText container">
      <div style="margin-top: 2rem">
        <p>
          Сәлем түзелмей, әлем түзелмейді. Сәлем — арабтың тыныштық, бейбітшілік
          мағынасындағы ассалам сөзінің тілімізде фонетикалық өзгеріске түсіп,
          қалыптасқан түрі. Сәлемдесу — тұңғыш рет немесе араға белгілі бір
          уақыт салып, кездескен таныс және бейтаныс адамдардың дәстүрлі сөзі,
          ишара не дене қимылы арқылы бір — біріне жақындық ниет, ілтипат
          білдіріп, жылы шырай танытуы.
        </p>
        <p>«Сәлем — сөздің басы» делінетіні де сондықтан.</p>
        <p>Ассалаумағалейкүм Құрметті қонақтар!</p>
        <p>
          Paradise Yard Мейрамханасының Авторлық және Әлем тамақтарын сіздерге
          ұсынамыз .
        </p>
      </div>
      <div class="textDivider"></div>
      <div>
        <p>
          The world will not be corrected without correcting it. Salam is the
          modern form of the Arabic word assalam, meaning peace! A greeting is a
          traditional speech, gesture or body gesture of acquaintances and
          strangers meeting for the first time or after a certain period of
          time, expressing a desire to be closer to each other, continuing
          respect and continuing warmth.
        </p>
        <p>That's why they say: “Hello is the beginning of a word.”</p>
        <p>- Assalamualaikum Dear guests!</p>
        <p>
          We offer you original and international dishes from the Paradise Yard
          restaurant.
        </p>
      </div>
      <div class="textDivider"></div>
      <div style="margin-bottom: 6rem">
        <p>
          Мир не будет исправлен без исправления его. Салам — фонетическая форма
          арабского слова ассалам, означающего мир ! Приветствие – это
          традиционная речь, жест или жест тела знакомых и незнакомых людей,
          встречающихся впервые или через определенный промежуток времени,
          выражающих желание быть ближе друг к другу, проявляющих уважение и
          проявляющих теплоту.
        </p>
        <p>Вот почему говорят: «Здравствуйте – это начало слова».</p>
        <p>- Ассалямуалейкум Уважаемые гости!</p>
        <p>
          Предлагаем Вам оригинальные и интернациональные блюда ресторана
          Paradise Yard.
        </p>
      </div>
    </div>
    <!-- КНОПКА НАЗАД -->
    <router-link to="/">
      <div class="back">Артқа • Back • Назад</div>
    </router-link>
    <!-- КНОПКА НАЗАД -->
  </div>
</template>

<style lang="scss" src="@/components/style.scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
.back {
  display: flex;
  justify-content: center;
  margin-bottom: 2.8rem;
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  color: #613b1c;
  text-align: center;
}
</style>

<script></script>
