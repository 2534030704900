<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Тәттілер</div>
        <div class="foreign">Deserts • Десерты</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/cheesecake.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Чизкейк</div>
        <div class="textGrey">Cheesecake</div>
        <!-- <div class="textGrey">Сольянка с мясом</div> -->
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/snickers.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Сникерс</div>
        <div class="textGrey">Snickers Cake</div>
        <!-- <div class="textGrey">Сольянка с мясом</div> -->
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/kinder.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Киндер</div>
        <div class="textGrey">Kinder Cake</div>
        <!-- <div class="textGrey">Сольянка с мясом</div> -->
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        1390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Молочная девочка</div>
      <div class="textGrey">Milky Girl</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жемісті салат</div>
      <div class="textGrey">Fruit Salad</div>
      <div class="textGrey">Фруктовый салат</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жемістер жиынтығы</div>
      <div class="textGrey">Fruit Set</div>
      <div class="textGrey">Фруктовый набор</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      4190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>