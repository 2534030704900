<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Ұлттық тағамдар</div>
        <div class="foreign">National Dishes • Национальные блюда</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/bal.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>«Бал қуырдақ» сиыр етімен</div>
        <div class="textGrey">Bal Kuyrdak with beef</div>
        <div class="textGrey">Бал куырдак с говядиной</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3290 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/sirne.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Сірне</div>
        <div class="textGrey">Sirne</div>
        <div class="textGrey">Сирне</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3090 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/bukpa.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Бұқпа қазы</div>
        <div class="textGrey">Bukpa Kazy</div>
        <div class="textGrey">Букпа казы</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3390 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- PHOTO ITEM -->
  <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/asip.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>Әсіп</div>
        <div class="textGrey">Asip</div>
        <div class="textGrey">Асип</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        3090 Т
      </div>
    </div>
  </div>
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Бешбармақ</div>
      <div class="textGrey">Beshbarmak</div>
      <div class="textGrey">Бешбармак</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Бал қуырдақ» жылқы етімен</div>
      <div class="textGrey">Bal kuyrdak with horse meat</div>
      <div class="textGrey">Бал куырдак с кониной</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3390 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>«Қазақша қуырдақ»</div>
      <div class="textGrey">Kuyrdak in kazakh</div>
      <div class="textGrey">Куырдак по казахский</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тай Қуырдақ</div>
      <div class="textGrey">Tai Kuyrdak</div>
      <div class="textGrey">Тай куырдак</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Борша ет</div>
      <div class="textGrey">Borsha meat</div>
      <!-- <div class="textGrey">Борша ет</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қымыз қыбырға</div>
      <div class="textGrey">Kumyz Ribs</div>
      <div class="textGrey">Кымыз кабырга</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      3490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жілік май</div>
      <div class="textGrey">Bone marrow</div>
      <div class="textGrey">Косный мозг</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
