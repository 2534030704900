<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Шәйлар</div>
        <div class="foreign">Tea • Чай</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Қазақша Шәй 1л</div>
      <div class="textGrey">Kazakh tea</div>
      <div class="textGrey">Казахский чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      990 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Paradise tea</div>
      <div class="textGrey">1л</div>
      <!-- <div class="textGrey">Казахский чай</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Ташкент шәйі 1л</div>
      <div class="textGrey">Tashkent tea</div>
      <div class="textGrey">Ташкентский чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Морокко шәй 1л</div>
      <div class="textGrey">Morocan tea</div>
      <div class="textGrey">Мороканский чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Зімбір-Теңіз шырғанағы 1л</div>
      <div class="textGrey">Sea buckthorn with ginger</div>
      <div class="textGrey">Облепиха с имбирем</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Тропикалық шәй 1л</div>
      <div class="textGrey">Tropical tea</div>
      <div class="textGrey">Тропический чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1190 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Таңқурай жалбызбен 1л</div>
      <div class="textGrey">Raspberry mint tea</div>
      <div class="textGrey">Чай малина мята</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Попкорн шәйі 1л</div>
      <div class="textGrey">Tea with Popcorn</div>
      <div class="textGrey">Чай с попкорном</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Жасыл жапырақ шәйі 1л</div>
      <div class="textGrey">Green tea</div>
      <div class="textGrey">Зеленый чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Алмұрт Жасминмен 1л</div>
      <div class="textGrey">Pear Jasmine tea</div>
      <!-- <div class="textGrey">Зеленый чай</div> -->
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1090 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Түрік шәйі 1л</div>
      <div class="textGrey">Turkish tea</div>
      <div class="textGrey">Турецкий чай</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      2890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
