<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Гарнир</div>
        <div class="foreign">Garnish • Гарнир</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Күріш</div>
      <div class="textGrey">Rice</div>
      <div class="textGrey">Рис</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      590 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Шоқта піскен көкеніс</div>
      <div class="textGrey">Grilled vegetables</div>
      <div class="textGrey">Овощи на гриле</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Картоп бөлшектері</div>
      <div class="textGrey">Wooden potatoes</div>
      <div class="textGrey">Картошка по-деревянский</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Гавайлық күріш</div>
      <div class="textGrey">Hawaiine rice</div>
      <div class="textGrey">Говайский рис</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Картоп түйнектері</div>
      <div class="textGrey">Fries</div>
      <div class="textGrey">Фри</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>

