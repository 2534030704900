<template>
  <!-- header -->
  <div class="main">
    <div class="main__logo3">
      <img src="@/assets/menu.svg" alt="" />
    </div>
    <div>
      <div class="name">
        <div class="kaz">Салқын сусындар</div>
        <div class="foreign">Cold Drinks • Холодные напитки</div>
      </div>
    </div>
  </div>
  <!-- header -->

  <!-- PHOTO ITEM -->
  <!-- <div class="photoItem container">
    <div class="photoItem__photo">
      <img src="@/assets/rbeye.png" alt="" />
    </div>
    <div class="photoItem__text">
      <div class="photoItem__name">
        <div>R-Beye</div>
        <div class="textGrey">Pauline</div>
        <div class="textGrey">Сольянка с мясом</div>
      </div>
      <div class="photoItem__cost">
        <div class="photoItem__divider"></div>
        6390 Т
      </div>
    </div>
  </div> -->
  <!-- PHOTO ITEM -->

  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Cola</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Cola</div>
      <div class="textGrey">0,25л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      490 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Piko</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Piko</div>
      <div class="textGrey">0.2л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Gracia</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      890 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Tassay</div>
      <div class="textGrey">0.75л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      1290 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Tassay</div>
      <div class="textGrey">0.5л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Bon Aqua</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      690 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Компот</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->
  <!-- TEXT ITEM -->
  <div class="photoItem__text container gap">
    <div class="photoItem__name">
      <div>Морс</div>
      <div class="textGrey">1л</div>
    </div>
    <div class="photoItem__cost">
      <div class="photoItem__divider"></div>
      790 Т
    </div>
  </div>
  <!-- TEXT ITEM -->

  <!-- FOOTER -->
  <the-footer></the-footer>
  <!-- FOOTER -->
</template>

<style lang="scss" src="@/components/style_dishes.scss"></style>
